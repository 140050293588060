import Validator from "validate.js"

export function validate_url(text: string): string | null {
  if (!text || text.length == 0) {
    return null
  }

  const options = {
    website: {
      url: {
        schemes: [".+"],
        allowLocal: true,
      },
    },
  }

  const result = Validator.validate({ website: text }, options)

  if (result && result.website) {
    const result2 = Validator.validate({ website: "http://" + text }, options)

    if (result2 && result2.website) {
      return "Invalid URL"
    }
  }
}
